import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import transportsService from '../services/transports';
import validationConstants from '../../ressources/validationConstants';

/**
 * Hook pour les formulaires en lien avec les véhicules
 * @param {Function} submitAction Fonction appelé pour soumettre le formulaire
 * @param {Object} initialValue Valeurs initiales des champs
 * @return {Object}
 */
export default function useTransportForm(
    submitAction,
    initialValue,
) {
  const [vehiclesGoogle, setVehiclesGoogle] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  useEffect(() => {
    transportsService.getVehicleGoogleEnum().then((res) => {
      setVehiclesGoogle(res.vehicle_google_enumeration);
    });
    setVehicleTypes(transportsService.getAllTransportTypes());
  }, []);

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.name_fr = values.name_fr.trim();
      values.name_en = values.name_en.trim();
      submitAction(values);
    },
    validate: (values) => {
      const errors = {};

      if (!values.name_fr || values.name_fr.trim() == '') {
        errors.name_fr = 'Veuillez indiquer le nom du véhicule.';
      } else if (values.name_fr.length > validationConstants.LIMIT_DEFAULT) {
        errors.name_fr = 'Le nom du véhicule peut contenir au maximum ' +
        `${validationConstants.LIMIT_DEFAULT} caractères.`;
      }

      if (!values.name_en || values.name_en.trim() == '') {
        errors.name_en = 'Veuillez indiquer la traduction anglaise' +
        ' du nom du véhicule.';
      } else if (values.name_en.length > validationConstants.LIMIT_DEFAULT) {
        errors.name_en = 'Le nom du véhicule peut contenir au maximum ' +
        `${validationConstants.LIMIT_DEFAULT} caractères.`;
      }

      if (values.avg_distance_km < '0') {
        errors.avg_distance_km =
        'Veuillez indiquer un nombre positif';
      }

      if (values.ges_rate < '0' ) {
        errors.ges_rate =
        'Veuillez indiquer un nombre positif';
      }

      if (!values.ges_rate && values.ges_rate != '0') {
        errors.ges_rate = 'Veuillez indiquer le taux d\'émission de GES.';
      }

      if (!values.avg_distance_km && values.avg_distance_km != '0') {
        errors.avg_distance_km =
         'Veuillez indiquer la moyenne de km par distance.';
      }

      if (!values.vehicle_google_enumeration_id) {
        errors.vehicle_google_enumeration_id =
          'Veuillez choisir le véhicule de correspondance' +
          ' pour les calculs de Google.';
      }

      if (!values.color) {
        errors.color = 'Veuillez sélectionner la couleur à utiliser dans' +
        ' les graphiques.';
      }

      if (!values.type_id) {
        errors.type_id = 'Veuillez choisir la catégorie de transport' +
        ' correspondante.';
      }

      if (values?.info?.length > validationConstants.LIMIT_LONG) {
        errors.info = `L'information supplémentaire peut contenir au maximum ` +
        `${validationConstants.LIMIT_LONG} caractères.`;
      }

      return errors;
    },
  });

  return {
    formik,
    vehiclesGoogle,
    vehicleTypes,
  };
}
