import Moment from 'moment';


/**
 * permet de verifier si la date est valide pour l'envoi au backend
 * @param {Moment} rawValue la valeur de la date donne en Moment
 * @param {String} value la valeur de la string de sortie
 * @return {boolean} la valeur de la validation, vrai si valide
 */
function dateValidationMoment(rawValue, value) {
  return new Moment(rawValue).isValid() &&
      value !== 'NaN-NaN-NaN';
}

/**
 * permet de verifier si la date est vide
 * @param {Moment} rawValue la valeur de la date donne en Moment
 * @param {String} value la valeur de la string de sortie
 * @return {boolean}
 */
function dateValidationEmpty(rawValue, value) {
  return rawValue !== null && value !== null;
}

export {dateValidationMoment, dateValidationEmpty};
