import React, {useContext, useState} from 'react';
import {Button,
  Container,
  Divider,
  Grid,
  Tooltip,
  Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import CheckIcon from '@mui/icons-material/Check';
import FlagIcon from '@mui/icons-material/Flag';
import {PieChart} from 'react-minimal-pie-chart';
import exportEvent from '../../global/services/export';
import GoogleMap from '../../global/components/googleMap';
import {AuthContext} from '../../auth/contexts/AuthContext';
import '../styles/eventDetailPage.scss';
import useEventDetail from '../hooks/useEventDetail.js';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {Box} from '@mui/system';
import ModalConfirmArchive from '../modals/ModalConfirmArchive';
/**
 * Page pour les détails d'un événements
 * @return {JSX.Element}
 */
export default function EventDetailPage() {
  const {user} = useContext(AuthContext);
  const params = useParams();
  const {
    eventDetail,
    onViewFormsList,
    onEditEvent,
    onArchiveEvent,
    reportVisible,
    transportPercentUsage,
    transportPercentGES,
    statsCovoiturage,
    formatDate,
  } = useEventDetail();
  const [popupArchive, setPopupArchive] = useState({
    isArchived: false, openArchiveModal: false, idToArchive: null, action: null,
  });
  const organizerName = eventDetail.organizer?.name ?
    eventDetail.organizer.name : <i>Aucun organisateur associé</i>;

  const organizerInfo = eventDetail.organizer?.info ?
    (<Grid item sm={6} data-testid="eventOrganizerInfo">
      <Typography variant="subtitle1">
        Informations sur l&apos;organisateur
      </Typography>
      <Typography variant={'body1'} >
        {eventDetail.organizer.info}
      </Typography>
    </Grid>) : null;

  const image = (eventDetail.logo === null || eventDetail.logo == '') ? null :
  ( <img src={eventDetail.logo} alt={'Logo de l\'événement'} /> );


  return (
    <Container>
      <Typography variant={'h1'} data-testid="eventName" mb={2}>
        {eventDetail.name}
        {user.level <= 3 ?
        <Tooltip placement="right"
          title={`${eventDetail.is_archived ?'Activer':'Désactiver'} 
          l'évènement`}>
          <IconButton
            data-testid="btnArchive"
            aria-label="Activer l'événement"
            onClick={() => {
              setPopupArchive({
                isArchived: eventDetail.is_archived,
                idToArchive: eventDetail.id,
                openArchiveModal: true,
                action: onArchiveEvent,
              });
            }}
            edge="end"
            sx={ eventDetail.is_archived ?
              {color: '#4D4D4D'} :
              {color: '#ED6B39'}}
          >
            {eventDetail.is_archived ?
                <ToggleOffIcon fontSize="large"/> :
                <ToggleOnIcon fontSize="large"/> }
          </IconButton>
        </Tooltip> :
            null}
      </Typography>
      <Divider/>
      <Grid container direction="row" spacing={1} my={3}
        justifyContent="space-between" textAlign="center">
        <Grid item xs={7} sm={3} md={2}>
          <Button data-testid="btnEditEvent" type="button" variant="contained"
            onClick={onEditEvent} >
            Modifer l&apos;événement
          </Button>
        </Grid>
        <Grid item xs={7} sm={3} md={2}>
          <Button data-testid="qr-button" type="button" variant="contained"
            component={Link} to={`/public/events/${params.id}/qr`}
            target="_blank" >
              Afficher le code QR
          </Button>
        </Grid>
        <Grid item xs={7} sm={3} md={2}>
          <Button data-testid="btnform" type="button" variant="contained"
            component={Link} to={`/public/events/${params.id}/forms/create`}
            target="_blank" >
            Accéder au formulaire
          </Button>
        </Grid>
        <Grid item xs={7} sm={3} md={2}>
          <Button data-testid="btnListParticipant" type="button"
            variant="contained" onClick={onViewFormsList} >
            Liste de participants
          </Button>
        </Grid>

        {user.level <= 3 ?
        <Grid item xs={7} sm={3} md={2}>
          <Button data-testid="btnExportReportform"
            type="button" variant="contained"
            onClick={() => exportEvent.buildTemplate(eventDetail)} >
            Exporter le rapport
          </Button>
        </Grid>: null }

      </Grid>
      <Box className="imgLogo" data-testid="eventLogo">
        {image}
      </Box>

      <Grid container direction="row" spacing={5}
        justifyContent="space-between" textAlign="center">
        <Grid item sm={6}>
          <Typography variant="subtitle1">Description (FR)</Typography>
          <Typography variant={'body1'} data-testid="eventDescriptionFr">
            {eventDetail.description_fr}
          </Typography>
        </Grid>

        <Grid item sm={6}>
          <Typography variant="subtitle1">Description (EN)</Typography>
          <Typography variant={'body1'} data-testid="eventDescriptionEn">
            {eventDetail.description_en}
          </Typography>
        </Grid>

      </Grid>
      <Grid container direction="row" spacing={5}
        justifyContent="space-between" textAlign="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Date</Typography>
          <Typography variant={'body1'} data-testid="eventDate">
            {formatDate()}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Statut</Typography>
          <Typography variant={'body1'} data-testid="eventStatus">
            {eventDetail.is_archived ? 'Inactif' : 'Actif'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={5}
        justifyContent="space-between" textAlign="center">
        <Grid item sm={6}>
          <Typography variant="subtitle1">
                Adresse
          </Typography>
          <Typography variant={'body1'} data-testid="eventAddress">
            {eventDetail.address}
          </Typography>
          <Typography variant="subtitle1">
                  Nombre de personnes présentes
          </Typography>
          <Typography variant={'body1'} data-testid="eventNbPersonExpected">
            {eventDetail.participations_number}
          </Typography>
        </Grid>
        <Grid item sm={6} className="google" data-testid="eventGoogle">
          <GoogleMap address={eventDetail.address} />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={5}
        justifyContent="space-between" textAlign="center">
        <Grid item sm={6}>
          <Typography variant="subtitle1">Organisé par</Typography>
          <Typography variant={'body1'} data-testid="eventOrganizer">
            {organizerName}
          </Typography>
        </Grid>
        {organizerInfo}
      </Grid>

      <Grid container direction="row" spacing={5}
        justifyContent="space-between" textAlign="center">
        <Grid item sm={6}>
          <Typography variant="subtitle1">Remerciements (FR)</Typography>
          <Typography variant={'body1'} data-testid="eventThanksFr">
            {eventDetail.thanks_message_fr}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="subtitle1">Remerciements (EN)</Typography>
          <Typography variant={'body1'} data-testid="eventThanksEn">
            {eventDetail.thanks_message_en}
          </Typography>
        </Grid>
      </Grid>

      <Box mb={5}>
        <Typography variant="subtitle1">Autres informations</Typography>
        <Typography variant={'body1'} data-testid="eventMoreInfo">
          {eventDetail.info}
        </Typography>
      </Box>

      <Divider/>

      {reportVisible && (
        <>
          <Typography variant="subtitle1" data-testid="GESTitle">
              Rapport des GES émis
          </Typography>
          <Typography variant={'body1'} data-testid="buttonPersonList"
            className={`flag ${eventDetail.flag_amounts ? 'red' : 'green'}`}>
            {eventDetail.flag_amounts ? eventDetail.flag_amounts : 0}
            {eventDetail.flag_amounts > 1 ?
                ' formulaires signalés' :
                ' formulaire signalé'}

            <IconButton aria-label="go-to-forms" data-testid="go-to-forms"
              onClick={onViewFormsList} >
              {eventDetail.flag_amounts ?
                <FlagIcon sx={{color: 'red'}} />:
                <CheckIcon sx={{color: 'green'}}/>}
            </IconButton>

          </Typography>
          <Grid container direction="row" spacing={5}
            justifyContent="space-between" textAlign="center">
            <Grid item sm={6}>
              <Typography variant="subtitle1">
                Nombre de répondants
              </Typography>
              <Typography variant={'body1'} data-testid="eventParticipant">
                {eventDetail.answers_amounts}
              </Typography>
            </Grid>

            <Grid item sm={6}>
              <Typography variant="subtitle1">
                  Total des GES émis par les répondants
              </Typography>
              <Typography variant={'body1'} data-testid="eventGESEmitted">
                {eventDetail.form_ges == null ? '0.00' :
                eventDetail.form_ges.toFixed(2).toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} kg
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={5}
            justifyContent="space-between" textAlign="center">
            <Grid item sm={6}>
              <Typography variant="subtitle1">
                  Moyenne des moyens de transports
              </Typography>
              <Typography variant={'body1'} data-testid="avgTransport">
                {eventDetail.avg_all_transport == null ? '0.000000' :
                eventDetail.avg_all_transport.toFixed(6).toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                } kgCO₂ éq/km/pers
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="subtitle1">
                  Total estimé des GES  émis par l&apos;événement
              </Typography>
              <Typography variant={'body1'} data-testid="eventGESExpected">
                {eventDetail.total_ges == null ? '0' :
                      eventDetail.total_ges.toFixed(2).toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} kg
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={5}
            justifyContent="space-between" textAlign="center">
            <Grid item sm={6}>
              <Typography variant="subtitle1">
                Pourcentage de véhicules avec plus d&apos;un passager
              </Typography>
              <Typography variant={'body1'} data-testid="avgTransport">
                {statsCovoiturage.percentCovoiturage}%
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="subtitle1">
                  Moyenne de personnes par véhicule
              </Typography>
              <Typography variant={'body1'} data-testid="eventGESExpected">
                {statsCovoiturage.persPerCar}
              </Typography>
            </Grid>
          </Grid>

          <Box data-testid="piechartsEvent">
            <Typography variant="subtitle1" textAlign="center">
                  Pourcentage des moyens de transport utilisés
            </Typography>
            <PieChart
              data={transportPercentUsage}
              radius={17}
              data-testid="pieChartLeft"
              paddingAngle={5}
              lineWidth={30}
              label={({dataEntry}) =>
                    dataEntry.value > 0 ?
                      dataEntry.title + ': ' +
                      Math.round(dataEntry.value * 100) / 100 + ' %' : ''
              }
              labelStyle={() => ({
                fill: '#222',
                fontSize: '1.5px',
                fontFamily: 'sans-serif',
              })}
              labelPosition={104}
              viewBoxSize={[100, 60]}
              center={[50, 30]}
            />
            <Typography variant="subtitle1" textAlign="center">
                  Pourcentage de GES émis par moyen de transport
            </Typography>
            <PieChart data-testid="pieChartRight"
              data={transportPercentGES}
              radius={17}
              paddingAngle={5}
              lineWidth={30}
              label={({dataEntry}) =>
                    dataEntry.value > 0 ?
                      dataEntry.title + ': ' +
                      Math.round(dataEntry.value * 100) / 100 + ' %' : ''
              }
              labelStyle={() => ({
                fill: '#222',
                fontSize: '1.5px',
                fontFamily: 'sans-serif',
              })}
              labelPosition={104} // 112
              viewBoxSize={[100, 60]}
              center={[50, 30]}
            />
          </Box>
        </>
      )}
      <ModalConfirmArchive popup={popupArchive}/>
    </Container>
  );
}
