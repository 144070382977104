import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';


/**
 * permet de faire la génération de la map google
 * @param {String} adress adresse à afficher
 * @return {JSX.Element}
 */
export default function GoogleMap({address}) {
  if (address !== undefined) {
    const addressConversion = address.replace(' ', '+');
    return (
      <iframe
        className="google-frame"
        title="Position géographique de l'événement"
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API}&q=${addressConversion}`}
      />
    );
  }
  return null;
}
GoogleMap.propTypes = {
  address: PropTypes.string,
};
