import {Button, Container, Grid, Stack, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {useNavigate, useParams} from 'react-router';
import {AuthContext} from '../../auth/contexts/AuthContext';
import ResponsesList from '../components/ResponsesList';
import ExcelImport from '../../global/components/excel/ExcelImport';
import exportFormulaire from '../../global/services/exportFormulaire';
import '../styles/responsesListPage.scss';
/**
 * Page de la liste des formulaires
 * @return {JSX.Element} la page de la liste des formualaires
 */
function ResponsesListPage() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {updateTokenCount, user} = useContext(AuthContext);

  return (
    <Container>
      <Grid container justifyContent="space-between"
        textAlign="center" spacing={2}>
        <Grid item md={6}>
          <Typography variant={'h1'} data-testid='usersListTitle'>
            Liste des formulaires
          </Typography>
        </Grid>
        <Grid item md={6}>
          {user.level <= 3 ? (
          <Stack direction="row" spacing={2} justifyContent="center">
            <ExcelImport reloadList={() => {
              updateTokenCount();
              navigate(0);
              // / reloadList(id); // TODO: va générer un erreur
            }} />
            <Button onClick={() => exportFormulaire.buildExcelFile(id)}
              variant='contained'>
                Exporter
            </Button>
          </Stack>
          ):null}
        </Grid>
      </Grid>
      <ResponsesList/>
    </Container>
  );
}

export default ResponsesListPage;

