import axios from 'axios';

/**
 * Retourne la liste des événements
 * @return {JSON}
 */
async function getAllEvents() {
  const res = await axios.get('events');
  return res;
}

/**
 * Fonction qui retourne un événements précis
 * @param {Number} id Le id de l'événements
 * @return {Promise}
 */
async function getEvent(id) {
  return axios.get(`/events/${id}`);
}

/**
 * Permet d'archiver un événement
 * @param {*} id l'id de l'événement à archiver
 */
async function archiveEvent(id) {
  const res = await axios.post(`events/${id}/archive`);
  return res;
}

/**
 * Retourne l'info publique d'un évènement
 * @param {*} id Id de l'événement
 * @return {Object} Les infos
 */
async function getEventInfo(id) {
  return axios.get(`/events/${id}/public`);
}

/**
 * Retourne un objet contenant les moyens de transport classés par type
 * @return {Object} Les moyens de transports
 */
async function getTransportList() {
  const res = await axios.get(`/transports/public`);

  const transports = {
    vehicule: [],
    transit: [],
    air: [],
    zeroGES: [],
  };
  res.data.forEach((transport)=>{
    switch (transport.type.type_id) {
      case 2:
        transports.vehicule.push(transport);
        break;
      case 3:
        transports.transit.push(transport);
        break;
      case 5:
        transports.air.push(transport);
        break;
      case 1:
      case 4:
        transports.zeroGES.push(transport);
        break;
    }
  });

  return transports;
}

/**
 * call axios pour le formulaire de creation d'evenement
 * @param {Object} values valeur du formulaire
 * @return {axios} retourne la promesse de la Requete
 */
async function createEvent(values) {
  const data = new FormData();
  data.append('name', values.eventName );
  data.append('user_id', values.eventOrganizerId);
  data.append('start_date', values.eventDateStart);
  data.append('end_date', values.eventDateEnd);
  data.append('address', values.eventAddress);
  data.append('logo', values.eventLogoImageFile);
  data.append('thanks_photo', values.eventThanksImageFile);
  data.append('description_fr', values.eventDescriptionFr);
  data.append('description_en', values.eventDescriptionEng);
  data.append('thanks_message_fr', values.eventThanksFr);
  data.append('thanks_message_en', values.eventThanksEng);
  data.append('participations_number', values.numberParticipant);
  data.append('info', values.otherInfo);
  data.append('show_ges', values.showGES);
  return await axios.post('/events', data);
}

/**
 * Call axios pour modifier un évènement.
 * @param {Object} values valeur du formulaire
 * @param {string} id id fu formulaire
 * @param {boolean} recalculate si on doit recalculer les distances
 * @return {axios} retourne la promesse de la Requete
 */
async function updateEvent(values, id, recalculate) {
  const data = new FormData();
  data.append('name', values.eventName );
  data.append('user_id', values.eventOrganizerId);
  data.append('start_date', values.eventDateStart);
  data.append('end_date', values.eventDateEnd);
  data.append('address', values.eventAddress);
  data.append('logo', values.eventLogoImageFile);
  data.append('thanks_photo', values.eventThanksImageFile);
  data.append('description_fr', values.eventDescriptionFr);
  data.append('description_en', values.eventDescriptionEng);
  data.append('thanks_message_fr', values.eventThanksFr);
  data.append('thanks_message_en', values.eventThanksEng);
  data.append('participations_number', values.numberParticipant);
  data.append('info', values.otherInfo);
  data.append('is_archived', values.is_archived);
  data.append('show_ges', values.showGES);
  if (recalculate) data.append('recalculate', true);
  return await axios.put('/events/' + id, data);
}

/**
 * Permet de supprimer un événement
 * @param {Number} id de l'événement
 * @return {Promise}
 */
async function deleteEvent(id) {
  const res = await axios.delete(`/events/${id}`);
  return res;
}

const eventsService = {
  getAllEvents,
  getEvent,
  archiveEvent,
  getTransportList,
  createEvent,
  deleteEvent,
  updateEvent,
  getEventInfo,
};

export default eventsService;
