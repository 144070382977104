import React, {useContext} from 'react';
import EventList from '../components/EventList.js';
import {Button, Container, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import {AuthContext} from '../../auth/contexts/AuthContext.js';
/**
 * Page des événements
 * @return {JSX.Element}
 */
function EventsListPage() {
  const navigate = useNavigate();
  const {user} = useContext(AuthContext);

  return (
    <Container data-testid="events-page">
      <Grid container justifyContent="space-between"
        textAlign="center" marginBottom={3}>
        <Grid item sm={6}>
          <Typography variant={'h1'} data-testid='eventsListTitle'>
          Événements
          </Typography>
        </Grid>
        {user.level <= 3 ?
        (<Grid item sm={6}>
          <Button onClick={()=>navigate('/events/create')} variant='contained'
            data-testid='createEventBtn' >
              Ajouter un événement
          </Button>
        </Grid>
        ) : null}
      </Grid>
      <EventList />
    </Container>
  );
}

export default EventsListPage;
