import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {AuthContext} from '../../auth/contexts/AuthContext';
import {LangContext} from '../../global/contexts/LangContext';
import validationConstants from '../../ressources/validationConstants';
import eventsService from '../../events/services/events';
import responsesService from '../services/responses';
import Moment from 'moment';
import useLoading from '../../global/components/loader/useLoading';

/**
 * Costum hook for response form operation
 * @return {object}
 */
function useResponseForm() {
  const {id} = useParams();
  const navigate = useNavigate();

  const {getRes, getCurrentLang} = useContext(LangContext);
  const lang = getCurrentLang().toLowerCase();
  const {setIsLoading} = useLoading();
  const {updateTokenCount} = useContext(AuthContext);

  // Liste des transports classé par type (google)
  const [transportList, setTransportList] = useState([]);
  const [eventInfo, setEventInfo] = useState({
    name: '',
    imgSource: '',
    date: '',
  });
  const [alert, setAlert] = useState(undefined);
  const [hasNoEvent, setHasNoEvent] = useState(false);

  const [transportType, setTransportType] = useState();

  const [response, setResponse] = useState({
    start_point: '',
    transport_id: null,
    passengers_number: 1,
  });

  useEffect(() => {
    eventsService.getTransportList().then(((res)=>setTransportList(res)));
    eventsService.getEventInfo(id).then((res)=>{
      const start = new Moment(res.data.start_date)
          .parseZone().format('YYYY-MM-DD');
      const end = new Moment(res.data.end_date)
          .parseZone().format('YYYY-MM-DD');
      setEventInfo({
        name: res.data.name,
        imgSource: res.data.logo,
        date: start == end ? start : `${start} au ${end}`,
      });
      if (res.status === 204) setHasNoEvent(true);
    }).catch(()=>setHasNoEvent(true)).finally(()=>setIsLoading(false));
  }, [id]);


  /**
   * onHandleSubmit
   * @param {object} event
   */
  function onHandleSubmit(event) {
    event.preventDefault();
    const error = validate(response);
    if (error) {
      setAlert({severity: 'error', message: error});
      return;
    }
    responsesService.createResponse(id, response).then((res) => {
      if (res.status === 201 ) {
        setAlert(undefined);
        updateTokenCount();
        navigate(`/public/events/${id}/forms/thank-you`,
            {state: {ges: res.data.generated_ges}});
      } else {
        setAlert({
          severity: 'error',
          message: setErrorMessage(res.messages[lang]),
        });
      }
    });
  }

  const WALK = getRes('transport_type_1');
  const CAR = getRes('transport_type_2');
  const TRANSIT = getRes('transport_type_3');
  const BIKE = getRes('transport_type_4');
  const AIR = getRes('transport_type_5');

  /**
   *
   * @param {string} type
   */
  function onHandleClickTransportType(type) {
    setTransportType(type);
    switch (type) {
      case WALK:
        setResponse((prev) => {
          const id = transportList.zeroGES.find(
              (transport) => transport.name[lang] === WALK,
          ).id;
          return {...prev, passengers_number: 1, transport_id: id};
        });
        break;
      case BIKE:
        setResponse((prev) => {
          const id = transportList.zeroGES.find(
              (transport) => transport.name[lang] === BIKE,
          ).id;
          return {...prev, passengers_number: 1, transport_id: id};
        });
        break;
      default:
        setResponse((prev) => {
          return {...prev, passengers_number: 1, transport_id: null};
        });
        break;
    }
  }
  /**
   * onHandleSelectTransport
   * @param {string} name
   */
  function onHandleSelectTransport(name) {
    const id = getSubTransportList().find(
        (transport) => transport.name[lang] === name,
    ).id;
    setResponse((prev) => {
      return {...prev, transport_id: id};
    });
  }
  /**
   * return la liste de transport par catégorie
   * @param {string} type
   * @return {array}
   */
  function getSubTransportList() {
    switch (transportType) {
      case CAR: return transportList.vehicule;
      case TRANSIT: return transportList.transit;
      case AIR: return transportList.air;
      default: return null;
    }
  }

  /**
 * validate the response
 * @param {object} res
 * @return{string} the error string or undefined if it's good
 */
  function validate(res) {
    if (res.start_point.trim() === '') {
      return getRes('error_address');
    }
    if (res.start_point.length > validationConstants.LIMIT_DEFAULT) {
      return getRes('error_address_overflow',
          validationConstants.LIMIT_DEFAULT);
    }
    if (res.transport_id === null) {
      return getRes('error_transport');
    }
  }
  return {
    eventInfo,
    response, setResponse,
    hasNoEvent,
    alert,
    transportType,
    onHandleSubmit,
    onHandleClickTransportType,
    onHandleSelectTransport,
    getSubTransportList,
  };
}

export default useResponseForm;

