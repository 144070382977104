import React, {useContext, useEffect, useRef} from 'react';
import {AuthContext} from '../../../auth/contexts/AuthContext';
import {useParams} from 'react-router-dom';
import {Button} from '@mui/material';
import QRCode from 'qrcodejs2';
import './styles.scss';
import useLoading from '../../components/loader/useLoading';

const logo = require('../../../ressources/images/spinner.png');

/**
 * Page du code QR
 * @return {JSX.Element}
 */
function QRCodePage() {
  const {setDisplayNavbar, user} = useContext(AuthContext);
  const codeContainer = useRef(null);
  const params = useParams();
  const {setIsLoading} = useLoading();

  useEffect(() => {
    const qrcode = new QRCode('qr-code', {
      correctLevel: QRCode.CorrectLevel.H,
      width: 400,
      height: 400,
    });
    qrcode.makeCode(window.location.origin +
      `/public/events/${params.id}/forms/create`);

    setDisplayNavbar(false);
    setIsLoading(false);
  }, []);

  const download = () => {
    const link = codeContainer.current.childNodes[0];
    const logoRef = codeContainer.current.childNodes[1];
    const canvas = codeContainer.current.childNodes[2];
    const ctx = canvas.getContext('2d');

    ctx.drawImage(logoRef, 140, 140);

    const img = canvas.toDataURL('image/png', 1.0)
        .replace('image/png', 'image/octet-stream');

    link.download = `code-qr-${params.id}.png`;
    link.href = img;
    link.click();
  };
  return (
    <>
      <div id='qr-code-container' data-testid="qr-code-page">
        <div id='qr-code' data-testid="qr-code" ref={codeContainer}>
          <a/>
          <img id='logo' src={user.level !==3 ? logo : '' /* org. autonome */}/>
        </div>
      </div>

      <Button
        className="no-print"
        id="print-btn"
        data-testid="btnListParticipant"
        type="button"
        variant="contained"
        size='small'
        onClick={window.print}
        sx={{
          'backgroundColor': '#ed6b39',
          '&:hover': {
            backgroundColor: '#bc4c20',
          },
          'width': 'fit-content',
          'textTransform': 'none',
          'marginRight': '25px',
        }}
      >
          Imprimer
      </Button>

      <Button
        className="no-print"
        id="download-btn"
        data-testid="btnListParticipant"
        type="button"
        variant="contained"
        size='small'
        onClick={download}
        sx={{
          'backgroundColor': '#ed6b39',
          '&:hover': {
            backgroundColor: '#bc4c20',
          },
          'width': 'fit-content',
          'textTransform': 'none',
          'marginRight': '25px',
        }}
      >
          Enregistrer
      </Button>
    </>

  );
}

export default QRCodePage;
