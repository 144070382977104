import axios from 'axios';

/**
 * Récupère tous les utilisateurs à l'aide d'un call d'API
 *
 * @return {*} la liste des utilisateurs
 */
async function getAllUsers() {
  const res = await axios.get('users');
  return res;
};

/**
 * Fonction qui récupère un utilisateur selon son ID
 * @param {Number} id Le ID de l'utilisateur
 * @return {Object} Un utilisateur
 */
async function getUser(id) {
  const res = await axios.get(`/users/${id}`);
  return res;
}

/**
 * Permet de récupérer la liste des rôles disponibles
 */
async function getUserRoles() {
  const res = await axios.get('/roles');
  return res.data;
}

/**
 * Permet de lancer l'appel de création d'un utilisateur.
 * @param {*} user Un objet représentant un utilisateur
 * @return {*} Un objet représentant un utilisateur
 */
async function sendCreateUser(user) {
  const res = await axios.post('/users', user);
  return res;
}

/**
 * Permet de modifier le mot de passe d'un utilisateur
 * @param {string} password Nouveau mot de passe
 * @return {*} le résultat de la requête
 */
async function sendChangePassword(password) {
  return await axios.post('/users/password', password);
}

/**
 * Permet de lancer l'appel de modification d'un utilisateur.
 * @param {number|string} id L'identifiant de l'utilisateur
 * @param {Object} user Un objet représentant un utilisateur
 * @return {*} Un objet représentant un utilisateur
 */
async function sendUpdateUser(id, user) {
  return await axios.put(`/users/${id}`, user);
}

/**
 * Permet de get les organisateurs
 * @return {Array} la list des organisateurs
 */
async function getPromoters() {
  const promoters = [];

  const res = await axios.get('/users');
  const body = res.data;
  body.forEach((x) => {
    promoters.push(x);
  });

  return promoters;
}

/**
 * Permet de supprimer un utilisateur
 * @param {number} id l'id de l'utilisateur
 * @return {*} le résultat de la requête
 */
async function deleteUser(id) {
  const res = await axios.delete(`/users/${id}`);
  return res;
}

const usersService = {
  getUserRoles,
  sendCreateUser,
  sendUpdateUser,
  sendChangePassword,
  getAllUsers,
  getPromoters,
  getUser,
  deleteUser,
};

export default usersService;
