import React, {useContext, useState} from 'react';
import EventForm from '../components/EventForm';
import useEventForm from '../hooks/useEventForm';
import eventsService from '../services/events';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../auth/contexts/AuthContext';
import useLoading from '../../global/components/loader/useLoading';
import {createEmptyEvent} from '../factory/event';
import AlertMessage from '../components/AlertMessage';
import {Container, Typography} from '@mui/material';


/**
 * Page des événements
 * @return {JSX.Element}
 */
function EventCreationPage() {
  const navigate = useNavigate();
  const {setIsLoading} = useLoading();
  const {user, updateTokenCount} = useContext(AuthContext);
  const [alert, setAlert] = useState(undefined);
  /**
     * action de submit pour le form
     * @param {*} values les valeurs du form
     */
  function submitAction(values) {
    setIsLoading(true);
    eventsService.createEvent(values).then((res) => {
      setAlert(undefined);
      updateTokenCount();
      navigate('/events/'+res.data.id+'/details');
    }).catch((error) => {
      const errorCode = error.response;
      if (errorCode === undefined || errorCode.status === 404) {
        setAlert({
          severity: 'error',
          message: 'Une erreur réseau s\'est produite.',
        });
      } else if (errorCode.status === 400) {
        setAlert({
          severity: 'error',
          message: 'Une des informations est invalide.',
        });
      } else {
        setAlert({
          severity: 'error',
          message: 'Il y a eu une erreur. si cela persiste,' +
          ' veuillez contacter le support technique',
        });
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const organizer = (user.role === 4) ?
    {id: user.id, name: user.name} :
    {id: 0, name: ''};

  const eventHook = useEventForm(submitAction, createEmptyEvent(organizer));

  return (
    <Container data-testid="events-page">
      <Typography variant={'h1'} >Ajouter un événement</Typography>
      <EventForm
        submitText='Créer un événement'
        {...eventHook}
        mode='create'
      />
      <AlertMessage alert={alert} />
    </Container>
  );
}

export default EventCreationPage;
