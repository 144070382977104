import ObjetExcel from './ObjetExcel.js';
import {read} from 'xlsx';
import importService from '../../../services/import';


/**
   * On li le fichier donné par l'utilisateur
   * @param {String} actualFile fichier actuel à traiter
   * @param {Number} id l'id de l'événement
   * @return {Boolean} true = fichier Excel avec le bon format
   */
export const readExcel = (actualFile, id) =>
  new Promise((resolve, reject)=>{
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(actualFile);

    /*
    * On le met dans le format XLSX avant le traitement
    * Après le traitement on récupère le Json
    */
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = read(bufferArray, {type: 'buffer'});
      const sheetActual = wb.Sheets[wb.SheetNames[0]];

      /*
      * On regarde si la feuille Excel a le bon format
      * Transmet l'id de l'événement et le json
      * Et return true si tout va bien
      */
      if (sheetActual['A1']['v'] === 'Bon format') {
        const sheet = new ObjetExcel(wb.SheetNames[0], sheetActual);
        const jsonSheet = sheet.getJson();
        importService.importEventExcel(id, jsonSheet).then((res)=>{
          resolve(true);
        }).catch(()=>{
          resolve(false);
        });
      } else {
        resolve(false);
      }
    };


    /*
    * En cas d'erreur, on rejette le fichier
    */
    fileReader.onerror = (error) => {
      reject(error);
    };
  });


