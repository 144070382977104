import React, {useContext, useState, useEffect} from 'react';
import {AuthContext} from '../../auth/contexts/AuthContext';
import UserForm from '../components/UserForm';
import useUserForm from '../hooks/useUserForm';
import userServices from '../services/users';
import useLoading from '../../global/components/loader/useLoading';
import {createEmptyUser} from '../factory/user';
import AlertMessage from '../components/AlertMessage';
import {Container, Typography} from '@mui/material';

/**
 * La page pour modifier son propre profil
 * @return {JSX.Element} Le contenu JSX de la page
 */
export default function EditProfilePage() {
  const {user} = useContext(AuthContext);
  const {setIsLoading} = useLoading();
  const [initialValue, setInitialValue] = useState(createEmptyUser());
  const [alert, setAlert] = useState(undefined);

  useEffect(() => {
    setIsLoading(true);
    userServices.getUser(user.id).then((res) => {
      setInitialValue({
        login: res.data.login,
        name: res.data.name,
        password: '',
        confirmPassword: '',
        role_id: res.data.role.id,
        info: res.data.info,
        remaining_calls: res.data.remaining_calls,
      });
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  /**
   * Soumettre la modification du mot de passe
   * @param {*} values Les valeurs du formulaire
   */
  function submitAction(values) {
    setIsLoading(true);

    userServices.sendChangePassword(values.password).then(() => {
      setAlert({
        severity: 'success',
        message: 'Mot de passe modifié avec succès !',
      });
    }).catch(() => {
      setAlert({
        severity: 'error',
        message: 'Une erreur est survenue.',
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const userHook = useUserForm(submitAction, initialValue, 'profil');

  return (
    <Container>
      <Typography variant={'h1'} >Modifier le mot de passe</Typography>
      <UserForm
        submitText='Enregistrer'
        {...userHook}/>
      <AlertMessage alert={alert} />
    </Container>
  );
}
