import {IconButton} from '@mui/material';
import React, {useState, useCallback, useEffect} from 'react';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import {useNavigate} from 'react-router-dom';

/**
 * Composant du bouton de retour en haut de page.
 * @return {JSX.Element}
 */
export default function ScrollToTop() {
  const [y, setY] = useState(window.scrollY);
  const [canScroll, setCanScroll] = useState(false);

  /**
   * Permet de détecter le si la page est descendue.
   */
  const handleNavigation = useCallback(
      (e) => {
        const window = e.currentTarget;
        if (window.scrollY > 0) {
          setCanScroll(true);
        } else {
          setCanScroll(false);
        }
        setY(window.scrollY);
      }, [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation, useNavigate()]);

  return (
    <div className="scroll-to-top">
      {canScroll ? (
        <IconButton
          aria-label="scroll-to-top"
          data-testid="scroll-to-top"
          onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
          sx={{
            position: 'fixed',
            bottom: '7rem',
            right: '2rem',
          }}
        >
          <ArrowCircleUpIcon
            sx={{
              color: '#ed6b39',
              width: '50px',
              height: 'auto',
            }}
          />
        </IconButton>
      ) : null}
    </div>
  );
}
