import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AuthContextProvider from './auth/contexts/AuthContext';
import './axios.config.js';
import {BrowserRouter} from 'react-router-dom';
import {LoadingProvider} from './global/components/loader/useLoading';

ReactDOM.render(
    <React.StrictMode>
      <LoadingProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </BrowserRouter>
      </LoadingProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

