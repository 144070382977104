import axios from 'axios';

/**
 * Récupère tous les formulaires pour un événement
 * @param {string} id l'id de l'événement associé aux formulaires
 * @param {Object} data json
 * @return {array} les formulaires
 */
async function importEventExcel(id, data) {
  const res = await axios.post(`/events/${id}/import`, data);
  return res;
}

const importService = {importEventExcel};

export default importService;
