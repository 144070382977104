import React, {useContext} from 'react';
import Tooltip from '@mui/material/Tooltip';
import PaymentsIcon from '@mui/icons-material/Payments';
import {AuthContext} from '../../../auth/contexts/AuthContext';
import {Typography} from '@mui/material';

/**
 *
 * @param {*} param0
 * @return {jsx}
 */
function Jeton() {
  const {user, getTokenCount} = useContext(AuthContext);

  return user.level == 3 ? (
    <Tooltip placement="bottom"
      title={'Les jetons sont utilisés pour l\'API Google.' +
          '\n1 jeton = 1 appel'}>
      <Typography variant={'h3'} marginX={3} padding={1}
        border="1px solid #ccc"
        borderRadius={1}>
        {getTokenCount()}
        <PaymentsIcon />
      </Typography>
    </Tooltip>
  ) : null;
}

export default Jeton;
