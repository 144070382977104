import React, {createContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import formEN from '../../ressources/languages/en.json';
import formFR from '../../ressources/languages/fr.json';
export const LangContext = createContext('');

/**
 * Contexte de langues
 * @param {*} children
 * @return {JSX.Element}
 */
export default function LangContextProvider({children}) {
  const dict = {
    'FR': formFR,
    'EN': formEN,
  };
  const supportedLangs = Object.keys(dict);
  const [currentLang, setCurrentLang] = useState('');

  useEffect(async () => {
    const browserLang = navigator.language.toUpperCase().slice(3);
    switch (browserLang) {
      case supportedLangs[1]:
        setCurrentLang(supportedLangs[1]);
        break;
      default:
        setCurrentLang(supportedLangs[0]);
        break;
    }
  }, []);

  /**
   * Retourne un string de la langue utilisé
   * @return {string} langue ie: EN, FR ...
   */
  const getCurrentLang = () => {
    return currentLang;
  };

  /**
   * Retourne un string de la prochaine langue utilisé
   * @return {string} langue ie: EN, FR ...
   */
  const getNextLang = () => {
    let index = supportedLangs.indexOf(currentLang) + 1;
    if (index >= supportedLangs.length) index = 0;

    return supportedLangs[index];
  };

  /**
   * Change le language actuel du dictionnaire.
   */
  const cycleLanguage = () => {
    setCurrentLang(getNextLang());
  };

  /**
   * Retourne un string de la langue actuelle selon une clé
   * @param {string} key Clé pour get la ressource dans le dictionnaire
   * @param {any} param String pour remplacer le placholder
   * @return {string} String à afficher
   */
  const getRes = (key, param = undefined) => {
    try {
      let str = dict[currentLang][key];
      if (param != undefined) {
        str = str.replace('%placeholder%', param);
      }
      return str;
    } catch {
      return '';
    }
  };

  return (
    <LangContext.Provider value={{
      getCurrentLang,
      getNextLang,
      cycleLanguage,
      getRes,
    }}>
      {children}
    </LangContext.Provider>
  );
}
LangContextProvider.propTypes = {
  children: PropTypes.object,
};
