import axios from 'axios';

/**
 * Permet de récupérer les résultats de recherche
 * @param {String} keywords les mots recherchés
 * @return {Promise} Les résulats de recherche
 */
async function search(keywords) {
  const res = await axios.post('/search', {keywords: keywords});
  return res;
}

const searchService = {search};

export default searchService;
