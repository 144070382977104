import {FormControl, InputLabel, Select, MenuItem, Button,
  TextField, Box, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import {ChromePicker} from 'react-color';
import '../styles/transportForm.scss';

/**
 * Composant d'un formulaire pour un véhicule
 * @return {JSX.Element}
 */
export default function TransportForm({
  submitText, formik, vehiclesGoogle, vehicleTypes,
}) {
  const [color, setColor] = useState(formik.values.color);

  useEffect(()=>{
    if (color !== formik.values.color) setColor(formik.values.color);
  }, [formik.values.color]);

  const googleDropdownOptions = vehiclesGoogle.map((vehicleGoogle) => (
    <MenuItem key={vehicleGoogle.id} value={vehicleGoogle.id} >
      {vehicleGoogle.name_fr}
    </MenuItem>
  ));

  const typeDropDownOptions = vehicleTypes.map((type) => (
    <MenuItem key={type.id} value={type.id} >
      {type.name_fr}
    </MenuItem>
  ));

  const showError = (key) => {
    if (formik.touched[key] && Boolean(formik.errors[key])) {
      return (<div className="form-errors">{formik.errors[key]}</div>);
    }
  };

  return (
    <form noValidate className="form-group" data-testid="form"
      onSubmit={formik.handleSubmit} >

      <Box className="container-input">
        <Typography variant="subtitle1" id="name">
          Nom du véhicule (Français)
        </Typography>
        <TextField id="name_fr" name="name_fr"
          inputProps={{'data-testid': 'transportNameFr'}}
          size="small"
          required
          autoFocus={true}
          error={formik.touched.name_fr && Boolean(formik.errors.name_fr)}
          variant="outlined"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.name_fr}
        />
        {showError('name_fr')}
      </Box>

      <Box className="container-input">
        <Typography variant="subtitle1" id="name">
            Nom du véhicule (Anglais)
        </Typography>
        <TextField id="name_en" name="name_en"
          inputProps={{'data-testid': 'transportNameEng'}}
          size="small"
          required
          autoFocus={true}
          error={formik.touched.name_en && Boolean(formik.errors.name_en)}
          variant="outlined"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.name_en}
        />
        {showError('name_en')}
      </Box>

      <Box className="container-input">
        <Typography variant="subtitle1" id="ges_rate">
            Taux d&apos;émission de GES (kg)
        </Typography>
        <TextField id="ges_rate" name="ges_rate"
          inputProps={{'data-testid': 'transportGesRate', 'min': 0}}
          size="small"
          required
          error={formik.touched.ges_rate && Boolean(formik.errors.ges_rate)}
          variant="outlined"
          type="number"
          onChange={formik.handleChange}
          value={formik.values.ges_rate}
        />
        {showError('ges_rate')}
      </Box>

      <Box className="container-input">
        <Typography variant="subtitle1" id="ges_rate">
            Moyenne de km par distance
        </Typography>
        <TextField id="avg_distance_km" name="avg_distance_km"
          inputProps={{'data-testid': 'avg_distance_km', 'min': 0}}
          size="small"
          required
          error={formik.touched.avg_distance_km &&
                  Boolean(formik.errors.avg_distance_km)}
          variant="outlined"
          type="number"
          onChange={formik.handleChange}
          value={formik.values.avg_distance_km}
        />
        {showError('avg_distance_km')}
      </Box>

      <Box className="container-input">
        <Typography variant="subtitle1" id="type">
            Catégorie
        </Typography>
        <FormControl fullWidth >
          <InputLabel id="typeDropdownLabel">
              Choisir une catégorie
          </InputLabel>
          <Select labelId="typeDropdownLabel" id="type_id" name="type_id"
            inputProps={{'data-testid': 'transportType'}}
            required
            error={formik.touched.type_id && Boolean(formik.errors.type_id)}
            value={formik.values.type_id}
            label="Choisir une catégorie"
            onChange={formik.handleChange}
          >
            {typeDropDownOptions}
          </Select>
        </FormControl>
        {showError('type_id')}
      </Box>

      <Box className="container-input">
        <Typography variant="subtitle1" id="vehicle_google_enumeration">
            Correspondance avec Google
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="vehicleGoogleDropdownLabel">
              Choisir un transport
          </InputLabel>
          <Select labelId="vehicleGoogleDropdownLabel"
            id="vehicle_google_enumeration_id"
            name="vehicle_google_enumeration_id"
            inputProps={ {'data-testid': 'transportVehicleGoogleEnumeration'} }
            required
            error={formik.touched.vehicle_google_enumeration_id &&
                Boolean(formik.errors.vehicle_google_enumeration_id)}
            value={formik.values.vehicle_google_enumeration_id}
            label="Choisir un transport"
            onChange={formik.handleChange}
          >
            {googleDropdownOptions}
          </Select>
        </FormControl>
        {showError('vehicle_google_enumeration_id')}
      </Box>

      <Box className="container-input">
        <Typography variant="subtitle1" id="color">
            Couleur dans les graphiques de rapport
        </Typography>
        <ChromePicker id="color" name="color"
          required
          disableAlpha
          color={color}
          onChange={(c) => setColor(c)}
          onChangeComplete={async (color) => {
            await formik.setFieldValue('color', color.hex);
          }}
          error={formik.touched.color && Boolean(formik.errors.color)}
        />
        {showError('color')}
      </Box>

      <Box className="container-input">
        <Typography variant="subtitle1" id="info-transport">
            Informations supplémentaires
        </Typography>

        <TextField
          multiline
          minRows={2}
          maxRows={10}
          id="info"
          name="info"
          size="small"
          inputProps={{'data-testid': 'info-transport'}}
          value={formik.values.info}
          onChange={formik.handleChange}
        />
        {showError('info')}
      </Box>

      <Button type="submit" variant="contained"
        data-testid="transportSubmitButton"
        sx={{'marginTop': '1rem'}} >
        {submitText}
      </Button>
    </form>
  );
}

TransportForm.propTypes = {
  submitText: propTypes.string.isRequired,
  formik: propTypes.object,
  vehiclesGoogle: propTypes.array,
  vehicleTypes: propTypes.array,
};
