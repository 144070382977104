import React from 'react';
import transportsServices from '../services/transports';
import TransportForm from '../components/TransportForm';
import useTransportForm from '../hooks/useTransportForm';
import {useNavigate} from 'react-router-dom';
import useLoading from '../../global/components/loader/useLoading';
import {createEmptyTransport} from '../factory/transport';
import {Container, Typography} from '@mui/material';

/**
 * Page d'ajout d'un véhicule
 * @return {JSX.Element}
 */
export default function CreateTransportPage() {
  const navigate = useNavigate();
  const {setIsLoading} = useLoading();

  /**
   * Fonction pour soumettre le formulaire de création
   * @param {*} values Les valeurs entrées dans formulaire
   */
  function submitAction(values) {
    setIsLoading(true);

    transportsServices.sendCreateTransport(values).then(() => {
      navigate('/transports');
    }).catch((err) => {
      console.log(err.response.data.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const transportHook = useTransportForm(submitAction, createEmptyTransport());

  return (
    <Container data-testid='events-page'>
      <Typography variant={'h1'} data-testid='CreateTransport'>
        Ajouter un véhicule
      </Typography>
      <TransportForm
        submitText='Créer le véhicule'
        {...transportHook}
      />
    </Container>
  );
}
