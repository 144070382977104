import React from 'react';
import './styles.scss';
import ScrollToTop from '../scrollToTop';

/**
 * Composant de pied de page.
 * @return {JSX.Element}
 */
export default function Footer() {
  return (
    <div className={
      window.location.pathname.includes('/public') ?
        'app-footer hide' :
        'app-footer footer-padding'
    }>
      <div className="footer-content">
        <span data-testid="footer">
        Copyright &#169; 2022 &#183; Tous droits réservés
          <br/>
        Conseil québécois des événements écoresponsables
        </span>
        <ScrollToTop />
      </div>
    </div>
  );
}
