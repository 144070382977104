import axios from 'axios';
import excel from 'exceljs';

const wb = new excel.Workbook();

/**
 * Permet de récupérer le template Excel d'export vierge.
 *
 * @return {ArrayBuffer} Le data du template Excel
 */
async function fetchTemplate() {
  const res = await axios.get(`/excel/event`, {
    responseType: 'arraybuffer',
  });

  return res.data;
}

/**
 * Permet d'ajouter les données d'un évènement au template Excel d'export.
 * @param {*} event Les informations de l'événement
 */
async function buildTemplate(event) {
  const template = fetchTemplate();

  wb.xlsx.load(template).then((data) => {
    const worksheet = data.worksheets[0];

    worksheet.getRow(2).height = 60;

    worksheet.getCell('A2').value = event.name;
    worksheet.getCell('B2').value = new Date(event.start_date);
    worksheet.getCell('C2').value = event.address;

    worksheet.getCell('A5').value = event.participations_number;
    worksheet.getCell('B5').value = event.total_ges;
    worksheet.getCell('C5').value = event.form_ges;
    worksheet.getCell('D5').value = event.answers_amounts;
    worksheet.getCell('E5').value = event.avg_all_transport;

    const cellStyle = {
      top: {color: {argb: '000'}, style: 'medium'},
      bottom: {color: {argb: '000'}, style: 'medium'},
      left: {color: {argb: '000'}, style: 'medium'},
      right: {color: {argb: '000'}, style: 'medium'},
    };

    // Pour les cellules A8, B8 et C8, on map les véhicules
    for (let i = 0; i < event.graph.length; i++) {
      const vehicule = event.graph[i];

      worksheet.getCell(`A${8 + i}`).value = vehicule.transport;
      worksheet.getCell(`A${8 + i}`).border = cellStyle;

      worksheet.getCell(`B${8 + i}`).value = vehicule.distance;
      worksheet.getCell(`B${8 + i}`).border = cellStyle;

      worksheet.getCell(`C${8 + i}`).value = vehicule.ges;
      worksheet.getCell(`C${8 + i}`).border = cellStyle;

      worksheet.getCell(`D${8 + i}`).value = vehicule.usage;
      worksheet.getCell(`D${8 + i}`).border = cellStyle;

      worksheet.getCell(`E${8 + i}`).value = vehicule.usage_percent / 100;
      worksheet.getCell(`E${8 + i}`).numFmt = '0.00%';
      worksheet.getCell(`E${8 + i}`).border = cellStyle;
    }

    downloadEvent(event);
  });
}

/**
 * Permet de télécharger le rapport d'un événement.
 * @param {*} event Les informations de l'événement
 */
function downloadEvent(event) {
  wb.xlsx.writeBuffer().then((buffer) => {
    const url = URL.createObjectURL(new Blob([buffer]));
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${event.name}.xlsx`;
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
  });
}

const exportEvent = {
  buildTemplate,
};

export default exportEvent;
