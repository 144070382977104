import axios from 'axios';
import responsesService from '../../responses/services/responses';
const Excel = require('exceljs');

/**
 * Créer un template pour export un fichier excel
 * pour la liste des formulaires d'un évènement
 * @param {*} id l'id du formulaire
 * @author Samuel Barriault
 */
async function buildExcelFile(id) {
  axios.get('/excel/forms', {responseType: 'arraybuffer'}).then((res) => {
    const excelData = res.data;
    const w = new Excel.Workbook();
    w.xlsx.load(excelData).then((workbook) => {
      const worksheet = workbook.worksheets[0];
      const table = worksheet.addTable({
        name: 'Tableau1',
        ref: 'A1',
        headerRow: true,
        style: {
          theme: 'TableStyleLight7',
          showRowStripes: true,
        },
        rows: [],
        columns: [
          {name: worksheet.getCell('A1').text, filterButton: true},
          {name: worksheet.getCell('B1').text, filterButton: true},
          {name: worksheet.getCell('C1').text, filterButton: true},
          {name: worksheet.getCell('D1').text, filterButton: true},
          {name: worksheet.getCell('E1').text, filterButton: true},
        ],
      });

      responsesService.getAllResponses(id).then((res) => {
        res.data.forEach((item) => {
          table.addRow([
            item.transport.name.fr,
            item.start_point,
            item.distance,
            item.generated_ges,
            item.passengers_number,
          ]);
        });
        table.commit();

        const columns = ['A', 'B', 'C', 'D', 'E'];
        columns.forEach((c) => {
          for (let i = 2; i < res.data.length + 2; i++) {
            const cell = worksheet.getCell(c + i);
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
            cell.font = {
              color: {argb: '00000000'},
            };
          }
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const url = URL.createObjectURL(new Blob([buffer]));
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'Formulaire.xlsx';
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        });
      });
    });
  });
}

const exportFormulaire = {
  buildExcelFile,
};

export default exportFormulaire;
