import React from 'react';
import TransportsList from '../components/TransportsList';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router';
import {Container, Grid, Typography} from '@mui/material';

/**
 * La page qui affiche la liste des différents véhicules de l'API
 * @return {JSX.Element}
 */
function TransportsListPage() {
  const navigate = useNavigate();

  return (
    <Container data-testid="transports-screen">
      <Grid container justifyContent="space-between" textAlign="center"
        marginBottom={3}>
        <Grid item sm={6}>
          <Typography variant={'h1'} data-testid='transportsListTitle'>
          Véhicules
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Button variant="contained" onClick={() => navigate('./create')} >
            Ajouter un véhicule
          </Button>
        </Grid>
      </Grid>
      <TransportsList />
    </Container>
  );
}

export default TransportsListPage;
