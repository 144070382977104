import React, {useEffect, useState, useContext} from 'react';
import EventForm from '../components/EventForm';
import useEventForm from '../hooks/useEventForm';
import eventsService from '../services/events';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';
import Moment from 'moment';
import {AuthContext} from '../../auth/contexts/AuthContext';
import useLoading from '../../global/components/loader/useLoading';
import AlertMessage from '../components/AlertMessage';
import {createEmptyEvent} from '../factory/event';
import {Container, Typography} from '@mui/material';

/**
 * Page des événements et de la modif de l'event selectionné
 * @return {JSX.Element}
 */
function EventModificationPage() {
  const navigate = useNavigate();
  const {id} = useParams();
  const {setIsLoading} = useLoading();
  const [initialValue, setInitialValue] = useState(
      createEmptyEvent({id: 0, name: ''}),
  );
  const {updateTokenCount} = useContext(AuthContext);
  const [alert, setAlert] = useState(undefined);

  useEffect(() => {
    eventsService.getEvent(id).then((res) => {
      // formater les date
      const startDateRaw = new Moment(res.data.start_date).parseZone();
      const endDateRaw = new Moment(res.data.end_date).parseZone();
      // préparer le data pour vérifier s'il reste des tokens
      const roleId = res.data?.organizer?.roles.id;
      const remainingCalls = res.data?.organizer?.remaining_calls;
      const answersAmounts = res.data?.answers_amounts;

      setInitialValue({
        eventName: res.data.name,
        eventLogoName: 'Photo non modifiée',
        eventLogoImageFile: undefined,
        eventOrganizerId: res.data.organizer?.id || 0,
        eventOrganizerName: res.data.organizer?.name || '',
        eventDateStart: startDateRaw.format('YYYY-MM-DD'),
        rawEventDateStart: startDateRaw,
        eventDateEnd: endDateRaw.format('YYYY-MM-DD'),
        rawEventDateEnd: endDateRaw,
        eventAddress: res.data.address,
        eventDescriptionEng: res.data.description_en,
        eventDescriptionFr: res.data.description_fr,
        eventThanksEng: res.data.thanks_message_en,
        eventThanksFr: res.data.thanks_message_fr,
        eventThanksImageName: 'Photo non modifiée',
        eventThanksImageFile: undefined,
        otherInfo: res.data.info,
        numberParticipant: res.data.participations_number,
        showGES: res.data.show_ges,
        is_archived: res.data.is_archived,
        recalculate: false,
        notEnoughTokens: (roleId === 4 && remainingCalls < answersAmounts),
      });
    });
  }, []);

  /**
   * action de submit pour le form
   * @param {*} values les valeurs du form
   * @param {boolean} recalculate si on doit recalculer les distances
   */
  function submitAction(values, recalculate) {
    setIsLoading(true);
    eventsService.updateEvent(values, id, recalculate).then(() => {
      setAlert(undefined);
      updateTokenCount();
      navigate('/events/'+id+'/details');
    }).catch((error) => {
      const errorCode = error.response;
      if (errorCode === undefined || errorCode.status === 404) {
        setAlert({
          severity: 'error',
          message: 'Une erreur réseau s\'est produite.',
        });
      } else if (errorCode.status === 400) {
        setAlert({
          severity: 'error',
          message: 'Une des informations est invalide.',
        });
      } else {
        setAlert({
          severity: 'error',
          message: 'Il y a eu une erreur. si cela persiste,' +
          ' veuillez contacter le support technique',
        });
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const eventHook = useEventForm(submitAction, initialValue);

  return (
    <Container data-testid="events-page">
      <Typography variant={'h1'} >Modifier un événement</Typography>
      <EventForm
        submitText='Modifier l&apos;événement'
        {...eventHook}
        mode='update'
        oldEventAddress={initialValue.eventAddress}
      />
      <AlertMessage alert={alert}/>
    </Container>
  );
}

export default EventModificationPage;
