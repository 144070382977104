import React, {useContext} from 'react';
import {Container, Paper, Typography} from '@mui/material';
import ToggleLanguageBar from './ToggleLanguageBar';
import {LangContext} from '../../global/contexts/LangContext';


/**
 * Page affichée lorsqu'il n'y a pas d'évènement pour un formulaire donné
 * @return {JSX.Element}
 * @constructor
 */
function NoEventMessage() {
  const {getRes} = useContext(LangContext);

  return (
    <div className='page-background'>
      <ToggleLanguageBar/>
      <div className='center-page'>
        <Container sx={{
          'paddingY': '10px',
          'height': '100vh',
          'display': {sm: 'flex'},
          'justifyContent': {sm: 'space-between'},
        }}>
          <Paper elevation={0} sx={{
            'my': {xs: '50%', sm: '0px'},
            'mx': {sm: '20px'},
            'width': '100%',
          }}>
            <Container sx={{marginTop: '10rem'}} >
              <Typography variant={'h6'}
                sx={{'textAlign': 'center', 'color': '#4B4B4B'}}>
                {getRes('error_no_event')}
              </Typography>
            </Container>
          </Paper>
        </Container>
      </div>
    </div>
  );
}

export default NoEventMessage;
