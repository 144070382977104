import React, {useContext, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {AuthContext} from '../../auth/contexts/AuthContext';
import propTypes from 'prop-types';
import ModalConfirmDelete from '../../global/modals/ModalConfirmDelete';
import useUsersList from '../hooks/useUsersList';
import {useMediaQuery} from '@mui/material';
/**
 * La view de la liste des utilisateurs
 *
 * @param {object} props
 * @return {JSX.Element}
 */
function UsersList() {
  const {user} = useContext(AuthContext);
  const {onView, onEdit, onDelete, usersList} = useUsersList();
  const [popup, setPopup] = useState({
    title: '',
    openDeleteModal: false,
    idtoDelete: null,
    action: null,
  });

  const matchesSm = !useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const columns = [
    {
      field: 'name',
      headerName: 'Nom d\'utilsateur',
      flex: 1,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <p className='longName'>
        {params.row.name}
      </p>,
    },
    {
      field: 'roles',
      headerName: 'Rôle',
      hide: matchesSm,
      width: 200,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) =>
        `${params.row.roles.name}`,
      renderCell: (params) => <p className='longName'>
        {params.row.roles.name}
      </p>,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 200,
      renderCell: (params) => {
        return (
          <div className="buttons-container">
            <IconButton
              data-testid={'eyeIconDetailUser'}
              aria-label="Accéder à l'utilisateur"
              onClick={() => onView(params.row.id)}
              edge="end"
              sx={{color: '#ED6B39'}}>
              <VisibilityIcon/>
            </IconButton>
            {user.level < 2 ?
            <>
              <IconButton
                data-testid={'penIconEditUser'}
                aria-label="Modifier l'utilisateur"
                onClick={() => onEdit(params.row.id)}
                edge="end"
                sx={{
                  visibility: (user.id == params.row.id ? 'hidden' : 'visible'),
                  color: '#ED6B39',
                }}>
                <EditIcon/>
              </IconButton>
              <IconButton
                data-testid={'eyeIconDeleteUser'}
                aria-label="Supprimer l'utilisateur"
                onClick={() => {
                  setPopup({
                    title: 'Voulez-vous supprimer cet utilisateur ?',
                    idToDelete: params.row.id,
                    openDeleteModal: true,
                    action: onDelete,
                  });
                }}
                edge="end"
                sx={{
                  visibility: (user.id == params.row.id ? 'hidden' : 'visible'),
                  color: '#ED6B39',
                }}>
                <DeleteIcon/>
              </IconButton>
            </>:
            null}
          </div>
        );
      },
    },
  ];

  return (
    <div data-testid="UsersList" className='list-rows'>
      <div style={{height: '100%'}}>
        <DataGrid autoHeight style={{border: 'none'}}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-cell': {
              borderBottomColor: 'primary.main',
              lineHeight: 'unset !important',
              maxHeight: 'none !important',
              whiteSpace: 'normal',
              textAlign: 'center',
              wordBreak: 'break-word !important',
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'none !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottomColor: 'primary.main',
              textAlign: 'center',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          rows={usersList}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[50]}
        />
      </div>
      <ModalConfirmDelete popup={popup}/>
    </div>
  );
}
UsersList.propTypes = {
  onView: propTypes.func,
  onEdit: propTypes.func,
  usersList: propTypes.array,
};
export default UsersList;
