import axios from 'axios';

/**
 * Récupère tous les formulaires pour un événement
 * @param {string} id l'id de l'événement associé aux formulaires
 * @return {array} les formulaires
 */
async function getAllResponses(id) {
  const res = await axios.get(`/events/${id}/answers`);
  return res;
};

/**
 * Call d'api pour créer un formulaire
 * @param {int} id form id
 * @param {object} data form data
 * @return {Promise} le retour de l'api
 */
async function createResponse(id, data) {
  return await axios.post(`events/${id}/answers`, data);
}

/**
 * Call d'api pour delete un formulaire
 * @param {Number} eventsId l'id de l'évènement
 * @param {Number} formsId l'id du formulaire
 */
async function deleteResponse(eventsId, formsId) {
  return await axios.delete(`/events/${eventsId}/answers/${formsId}`);
}

/**
 * Permet de supprimer plusieurs formulaires
 * @param {number} eventId L'identifiant de l'évènement
 * @param {number[]} formIds Les identifiants des formulaires
 * @return {Promise<any>} La réponse
 */
async function deleteManyResponses(eventId, formIds) {
  return await axios.post(`/events/${eventId}/answers/deleteMany`,
      {ids: formIds});
}

/**
 * Permet de modifier un événement
 * @param {int} eventId l'id de l'événement
 * @param {int} formId l'id du formulaire
 * @param {*} data les nouvelles informations des nouvelles
 */
async function editResponse(eventId, formId, data) {
  const res = await axios.put(`events/${eventId}/answers/${formId}`, data);
  return res;
}

/**
 * Permet de récupérer la distance entre les deux points
 * @param {*} eventId l'id de l'événement
 * @param {*} data le nouveau code postal
 */
async function distanceBetween(eventId, data) {
  const res = await axios.post(`events/${eventId}/distance-between`, data);
  return res;
}

/**
 * Fonction qui permet de changer la validité d'un form
 * @param {Number} formID Le ID du form à changer
 * @param {Number} eventID Le ID de l'événement
 */
async function flagResponse(formID, eventID) {
  return await axios.put(`events/${eventID}/answers/${formID}/flagged`);
}

const responsesService =
{
  getAllResponses,
  createResponse,
  editResponse,
  deleteResponse,
  deleteManyResponses,
  distanceBetween,
  flagResponse,
};

export default responsesService;
