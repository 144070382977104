import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import useLoading from '../../global/components/loader/useLoading';
import {createEmptyTransport} from '../factory/transport';
import transportsService from '../services/transports';

/**
 * Costum hook for transport detail operation
 * @return {object}
 */
function useTransportDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [transportDetail, setTransportDetail] =
    useState(createEmptyTransport());
  const {setIsLoading} = useLoading();

  useEffect(() => {
    setIsLoading(true);
    transportsService.getTransport(id).then((res) => {
      setTransportDetail({
        name_fr: res.data.name.fr,
        name_en: res.data.name.en,
        ges_rate: res.data.ges_rate,
        vehicle_google_enumeration_id: res.data.type.google_id,
        color: res.data.color,
        type_id: res.data.type.type_id,
        avg_distance_km: res.data.avg_distance_km,
        info: res.data.info || '',
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  /**
   * Fonction qui permet d'aller sur la page de modification d'un transport
   */
  const onEdit = () => {
    const path = `/transports/${id}/edit`;
    navigate(path);
  };

  /**
   * Permet de trigger le modal de suppression d'un transport.
   */
  const onDelete = () => {
    setIsLoading(true);
    transportsService.deleteTransport(id).then(() => {
      navigate('/transports');
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return {onEdit, onDelete, transportDetail};
}
export default useTransportDetail;
