import React, {useState, useContext} from 'react';
import {Button, useMediaQuery} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import FlagIcon from '@mui/icons-material/Flag';
import {AuthContext} from '../../auth/contexts/AuthContext';
import ModalModifyResponse from '../modals/ModalModifyResponse';
import useResponsesList from '../hooks/useReponsesList';
import ModalConfirmDelete from '../../global/modals/ModalConfirmDelete';
import ModalConfirmDeleteMany from '../modals/ModalConfirmDeleteMany';

/**
 * Component de la liste des formulaires
 * @return {JSX.Element} la liste de formulaire
 */
function ResponsesList() {
  const {user} = useContext(AuthContext);
  const [selectedResponses, setSelectedResponses] = useState([]);
  const {
    onFlag, onEdit, onDelete, onDeleteMany, responsesList,
  } = useResponsesList();
  const [popupModify, setPopupModify] = useState({
    open: false, data: null, action: null,
  });
  const [popupDelete, setPopupDelete] = useState({
    title: '', idToDelete: null, openDeleteModal: false, action: null,
  });
  const [popupDeleteMany, setPopupDeleteMany] = useState({
    openDeleteManyModal: false, idsToDelete: null, action: null,
  });

  const matchesMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const columns = [
    {
      field: 'start_point',
      headerName: 'Point de départ',
      flex: 1,
      minWidth: 110,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.start_point,
      renderCell: (params) => <p className='longName'>
        {params.row.start_point}
      </p>,
    },
    {
      field: 'distance',
      headerName: 'Distance (aller)',
      width: 130,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.distance,
      renderCell: (params) => <p className='longName'>
        {params.row.distance} km
      </p>
      ,
    },
    {
      field: 'transport',
      headerName: 'Moyen de transport',
      width: 150,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => {
        return params.row.transport.name.fr !== null ?
          params.row.transport.name.fr : 'Véhicule introuvable';
      },
      renderCell: (params) => <p className='longName'>
        { params.row.transport.name.fr !== null ?
            params.row.transport.name.fr : 'Véhicule introuvable'}
      </p>,
    },
    {
      field: 'passengers_number',
      headerName: 'Passagers',
      width: 100,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => {
        return params.row.passengers_number || 'Inconnu';
      },
      renderCell: (params) => <p className='longName'>
        { params.row.passengers_number || 'Inconnu'}
      </p>,
    },
    {
      field: 'generated_ges',
      headerName: 'GES émis (aller-retour)',
      width: 170,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.generated_ges,
      renderCell: (params) => <p className='longName'>
        {params.row.generated_ges} kg
      </p>,
    },
    {
      field: 'is_valid',
      headerName: 'Valide',
      type: 'boolean',
      valueGetter: (params) => params.row.is_valid,
      renderCell: (params) => user.level !== 4 ?
        <IconButton
          data-testid="btnFlag"
          aria-label="Flagger le formulaire"
          onClick={() => onFlag(params.row.id) }
          edge="end"
          sx={{color: params.row.is_valid ? '#4D4D4D' : '#ED6B39'}}>
          <FlagIcon />
        </IconButton> :
        <FlagIcon sx={{color: params.row.is_valid ? '#4D4D4D' : '#ED6B39'}}/>,
    },
    {
      field: 'Actions',
      type: 'actions',
      width: 100,
      renderCell: (params) => {
        return (
          user.level <= 3 ?
          <div className="buttons-container">
            <IconButton aria-label="Modifier le formulaire"
              data-testid="btnEdit"
              onClick={()=>{
                setPopupModify({
                  open: true,
                  data: params.row,
                  action: onEdit,
                });
              }}
              edge="end"
              sx={{color: '#ED6B39'}}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="Supprimer le formulaire"
              data-testid="btnDelete"
              onClick={() => setPopupDelete({
                title: 'Voulez-vous supprimer cet événement ?',
                idToDelete: params.row.id,
                openDeleteModal: true,
                action: onDelete,
              }) }
              edge="end"
              sx={{color: '#ED6B39'}}>
              <DeleteIcon />
            </IconButton>
          </div> :
          null
        );
      },
    },
  ];

  return (
    <div id='wrapper-list' data-testid="list-forms">
      <DataGrid
        checkboxSelection={user.level < 4}
        onSelectionModelChange={(rowIds) => setSelectedResponses(rowIds)}
        autoHeight
        disableSelectionOnClick
        initialState={{

          pinnedColumns: {right: ['actions']},
        }}
        columnVisibilityModel={{
          passengers_number: matchesMd,
          generated_ges: matchesMd,
          flag: false,
        }}
        sx={{
          'border': 0,
          '& .flag': {
            outline: '5px solid #FFD54D',
            outlineOffset: '-6px',
          },
          '& .MuiDataGrid-cell': {
            borderBottomColor: 'primary.main',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal',
            textAlign: 'center',
            wordBreak: 'break-word !important',
          },
          '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottomColor: 'primary.main',
            textAlign: 'center',
            fontWeight: 'bold',
          },
        }}
        rows={responsesList}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        disableVirtualization={process.env.REACT_APP_MODE === 'test'}
        getRowClassName={(params) => {
          return params.row.is_valid ? '' : 'flag';
        }}
      />

      {selectedResponses.length > 0 ? (
        <Button variant='contained' onClick={() => {
          setPopupDeleteMany({
            openDeleteManyModal: true,
            idsToDelete: selectedResponses,
            action: onDeleteMany,
          });
        } } >
            Supprimer les données sélectionnées
        </Button>
      ) : null}

      <ModalModifyResponse popup={popupModify}/>
      <ModalConfirmDelete popup={popupDelete} />
      <ModalConfirmDeleteMany popup={popupDeleteMany} />
    </div>
  );
}
ResponsesList.propTypes = {
  onFlag: PropTypes.func,
  onEdit: PropTypes.func,
  responsesList: PropTypes.array,
};

export default ResponsesList;

