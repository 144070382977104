import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import eventsService from '../services/events';
import graphsUtils from '../utils/graphsUtils';
import Moment from 'moment';
import useLoading from '../../global/components/loader/useLoading';
import responsesService from '../../responses/services/responses';
/**
 * @param {integer} id
 */
async function calculateStats(id) {
  // Calculer le nombre de personne par voiture
  const responses = await responsesService.getAllResponses(id);
  const cars = responses.data.filter(
      (res)=> res.transport.type_id===2 && res.is_valid);
  if (cars.length === 0) return {persPerCar: 'Aucune voiture'};
  const nbCars = cars.length;
  const pers = cars.map((res)=>res.passengers_number);
  const nbPers = pers.reduce((a, b) => a + b, 0);
  const persPerCar = Math.round(nbPers/nbCars*100)/100;
  // % de véhicule qui covoiture (+ de 1 passagers / tout les véhicules)
  const nbCovoiturages = cars.filter((res)=>res.passengers_number > 1).length;
  const percentCovoiturage = Math.round(nbCovoiturages/nbCars*100*100)/100;
  return {persPerCar, percentCovoiturage};
}
/**
 * costum hook for event detail operations
 * @return {object}
 */
function useEventDetail() {
  const navigate = useNavigate();
  const {id} = useParams();
  const [eventDetail, setEventDetail] = useState({organizer: {}, graphs: {}});
  const [transportPercentUsage, setTransportPercentUsage] = useState([]);
  const [transportPercentGES, setTransportPercentGES] = useState([]);
  const [statsCovoiturage, setStatsCovoiturage] = useState({persPerCar: -1});
  const {setIsLoading} = useLoading();

  /**
  * Recharger l'évènement
  */
  function reloadEvent() {
    setIsLoading(()=>true);
    eventsService.getEvent(id).then((res) => {
      setEventDetail(res.data);
    }).finally(() => {
      setIsLoading(()=>false);
    });
  }

  useEffect(() => {
    reloadEvent();
  }, []);

  const reportVisible = (eventDetail.answers_amounts >= 1);
  useEffect(() => {
    if (reportVisible) {
      setTransportPercentUsage(
          graphsUtils.transportPercentUsage(eventDetail.graph),
      );
      setTransportPercentGES(
          graphsUtils.transportPercentGES(eventDetail.graph),
      );
      calculateStats(id).then((res)=>{
        setStatsCovoiturage(res);
      });
    }
  }, [eventDetail]);

  const onViewFormsList = () => {
    navigate(`/events/${id}/forms`);
  };

  const onEditEvent = () => {
    navigate(`/events/${id}/edit`);
  };

  const onCreateForm = () => {
    navigate(`/public/events/${id}/forms/create`);
  };

  const onArchiveEvent = () => {
    setIsLoading(()=>true);
    eventsService.archiveEvent(id).then(() => {
      reloadEvent();
    }).finally(() => {
      setIsLoading(()=>false);
    });
  };

  /**
     * Permet de formatter la date de début de l'évènement
     * @return {string} date de l'événement
     */
  function formatDate() {
    const start = new Moment(eventDetail.start_date)
        .parseZone().format('YYYY-MM-DD');
    const end = new Moment(eventDetail.end_date)
        .parseZone().format('YYYY-MM-DD');
    return start == end ? start : `${start} au ${end}`;
  };

  return {
    eventDetail,
    onViewFormsList,
    onEditEvent,
    onCreateForm,
    onArchiveEvent,
    reportVisible,
    transportPercentUsage,
    transportPercentGES,
    statsCovoiturage,
    formatDate,
  };
}

export default useEventDetail;
