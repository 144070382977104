import {Button, Container,
  Grid, Stack, Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import propTypes from 'prop-types';
import {AuthContext} from '../../auth/contexts/AuthContext';
import useTransportDetail from '../hooks/useTransportDetail';
import {ModalConfirmDelete} from '../../global/modals/ModalConfirmDelete';


/**
 * Transport detail component
 * @return {jsx}
 */
function TransportDetail() {
  const {user} = useContext(AuthContext);
  const {onEdit, onDelete, transportDetail} = useTransportDetail();
  const [popup, setPopup] = useState({
    title: '',
    openDeleteModal: false,
    idtoDelete: null,
    action: null,
  });

  const buttons = (
    <Stack direction="row" spacing={2}>
      <Button data-testid='btnEditTransport' type='button'
        variant='contained' onClick={onEdit}>
          Modifer le véhicule
      </Button>
      <Button data-testid='btnDeleteTransport' type='button'
        variant='contained' onClick={() => setPopup({
          title: 'Voulez-vous supprimer ce moyen de transport ?',
          openDeleteModal: true,
          idtoDelete: null,
          action: onDelete,
        })} >
          Supprimer le véhicule
      </Button>
    </Stack>);
  return (
    <Container>
      <Grid container direction="row" spacing={2} mb={5}
        justifyContent="space-between" textAlign="center">
        <Grid item md={6}>
          <Typography variant={'h1'} data-testid='transportName'>
            {transportDetail.name_fr}
          </Typography>
        </Grid>
        <Grid item md={6}>
          { user.role === 3 ? buttons : null}
        </Grid>
      </Grid>

      <Typography variant="subtitle1">
        Nom du véhicule (Français)
      </Typography>
      <Typography variant={'body1'} data-testid="transportNameFR">
        {transportDetail.name_fr}
      </Typography>

      <Typography variant="subtitle1">
        Nom du véhicule (Anglais)
      </Typography>
      <Typography variant={'body1'} data-testid="transportNameEN">
        {transportDetail.name_en}
      </Typography>

      <Typography variant="subtitle1">
        Taux d&apos;émission de GES (kg)
      </Typography>
      <Typography variant={'body1'} data-testid='GESRate'>
        {transportDetail.ges_rate}
      </Typography>

      <Typography variant="subtitle1">
        Distance moyenne (km)
      </Typography>
      <Typography variant={'body1'} data-testid='avgDistance'>
        {transportDetail.avg_distance_km}
      </Typography>

      <Typography variant="subtitle1">Couleur</Typography>
      <div style={{
        'marginTop': '10px',
        'marginLeft': '0.8rem',
        'backgroundColor': transportDetail.color,
        'width': '75px', 'height': '75px',
      }} />

      <Typography variant="subtitle1">
          Informations supplémentaires
      </Typography>
      <Typography variant={'body1'} data-testid='avgDitransportInfostance'>
        {transportDetail.info ? transportDetail.info :
          <p className="generic-message">Aucune information disponible.</p>}
      </Typography>
      <ModalConfirmDelete popup={popup}/>
    </Container>
  );
}
TransportDetail.propTypes = {
  onEdit: propTypes.func,
  transportDetail: propTypes.object,
};
export default TransportDetail;
