import React, {useContext} from 'react';
import {LangContext} from '../../global/contexts/LangContext';
import {AppBar, Button} from '@mui/material';
import PropTypes from 'prop-types';


/**
 * Bouton toggle pour choisir son mode de transport.
 * @return {JSX.Element}
 * @constructor
 */
function ToggleLanguageBar() {
  const {cycleLanguage, getNextLang} = useContext(LangContext);

  return (
    <AppBar position='static' sx={{
      'display': 'flex',
      'backgroundColor': '#4B4B4B',
      'padding': '0.5rem',
    }}>
      <Button
        data-testid='lang-btn'
        color='inherit'
        onClick={cycleLanguage}
        sx={{marginLeft: 'auto'}}>
        {getNextLang()}
      </Button>
    </AppBar>
  );
}

ToggleLanguageBar.propTypes = {
  onClick: PropTypes.func,
};

export default ToggleLanguageBar;
