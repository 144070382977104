import React, {useContext, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import DeleteIcon from '@mui/icons-material/Delete';
import {AuthContext} from '../../auth/contexts/AuthContext';
import Moment from 'moment';
import ModalConfirmDelete from '../../global/modals/ModalConfirmDelete';
import useEventList from '../hooks/useEventList';
import ModalConfirmArchive from '../modals/ModalConfirmArchive';
/**
 * Le component de la liste des événements
 *
 * @param {object} props
 * @return {JSX.Element}
 */
function EventList() {
  const {user} = useContext(AuthContext);

  const {onView, onArchive, onDelete, eventsList} = useEventList();
  const [popupDelete, setPopupDelete] = useState({
    title: '', openDeleteModal: false, idtoDelete: null, action: null,
  });
  const [popupArchive, setPopupArchive] = useState({
    isArchived: false, openArchiveModal: false, idToArchive: null, action: null,
  });

  const matchesSm = !useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const matchesMd = !useMediaQuery((theme) => theme.breakpoints.up('md'));

  const columns = [
    {field: 'flag_amounts', headerName: 'Formulaires\n signalés',
      hide: matchesMd,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.flag_amounts,
    },
    {field: 'name', headerName: 'Nom', flex: 1,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <p className='longName'>{params.row.name}</p>,
    },
    {field: 'user', headerName: 'Organisateur', width: 200,
      hide: matchesMd,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.organizer,
      renderCell: (params) =>
        <p className='longName'>{params.row.organizer}</p>,
    },
    {field: 'start_date', headerName: 'Date',
      hide: matchesSm,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      renderCell: (params) =>
        <p className='longName'>
          {new Moment(params.row.start_date)
              .parseZone().format('YYYY-MM-DD')}
        </p>,
      valueGetter: (params) =>
        new Moment(params.row.start_date)
            .parseZone().format('YYYY-MM-DD'),
    },
    {field: 'is_archived', headerName: 'Statut',
      hide: matchesMd,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.is_archived ? 'Inactif' : 'Actif',
    },
    {field: 'actions', headerName: '', width: 200,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="buttons-container">
            <IconButton
              data-testid={'eyeIconDetailEvent'}
              aria-label="Accéder à l'événement"
              onClick={()=> onView(params.row.id)}
              edge="end"
              sx={{color: '#ED6B39'}}>
              <VisibilityIcon/>
            </IconButton>
            {user.level <= 3 ?
            <IconButton
              data-testid="btnArchive"
              aria-label="Activer l'événement"
              onClick={() => {
                setPopupArchive({
                  isArchived: params.row.is_archived,
                  idToArchive: params.row.id,
                  openArchiveModal: true,
                  action: onArchive,
                });
              }}
              edge="end"
              sx={ params.row.is_archived ?
              {color: '#4D4D4D'} :
              {color: '#ED6B39'}}
            >
              {params.row.is_archived ?
                <ToggleOffIcon fontSize="large"/> :
                <ToggleOnIcon fontSize="large"/> }
            </IconButton> :
            null}
            {user.level <= 3 ?
              <IconButton
                data-testid="btnDelete"
                aria-label="Supprimer l'événement"
                onClick={() => {
                  setPopupDelete({
                    title: 'Voulez-vous supprimer cet événement ?',
                    idToDelete: params.row.id,
                    openDeleteModal: true,
                    action: onDelete,
                  });
                }}
                edge="end"
                sx={{color: '#ED6B39'}}
              >
                <DeleteIcon/>
              </IconButton> :
            null}
          </div>
        );
      },
    },
  ];

  return (
    <div data-testid="events-list" className='list-rows'>
      <DataGrid
        disableSelectionOnClick
        autoHeight
        sx={{
          'border': 0,
          '& .MuiDataGrid-cell': {
            borderBottomColor: 'primary.main',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal',
            textAlign: 'center',
            wordBreak: 'break-word !important',
          },
          '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottomColor: 'primary.main',
            textAlign: 'center',
          },
        }}
        rowHeight={60}
        rows={eventsList}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        disableVirtualization = {process.env.REACT_APP_MODE === 'test'}
      />

      <ModalConfirmDelete popup={popupDelete}/>
      <ModalConfirmArchive popup={popupArchive}/>
    </div>
  );
}

export default EventList;
